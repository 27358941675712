const smoothScroll = (targetIdPrefix, wrapperSelector = '') => {
  $(`${wrapperSelector} a[href^="${targetIdPrefix}"]`).click((e) => {
    const speed = 800
    const href= $(e.currentTarget).attr('href')
    const position = $(href).offset().top
    $('html, body').animate({ scrollTop: position }, speed, 'swing')
    return false
  })
}

window.smoothScroll = smoothScroll
