$(() => {
  const $audioUploadForm = $('.js-audio-upload-form')
  const $audioEntryForm = $('.js-audio-entry-form')

  // 作品ファイルアップロード
  if ($audioUploadForm.is('*')) {
    const $fileField = $('.js-audio-upload-file-field')
    const $nameField = $('.js-audio-name')
    const $submitButton = $('.js-audio-submit-btn')
    const $player = $('.js-upload-file-player')
    const $error = $('.js-upload-file-error')

    $fileField.on('change', (e) => {
      $error.empty()
      $player.empty()

      const file = e.target.files[0]
      let error = ''

      if (!file) {
        e.preventDefault()
        return false
      }

      // File API が使用可能な場合は簡易バリデーションをかける
      if (window.FileReader && window.Blob) {
        if (!file) {
          // ファイルが指定されなかった場合は何もしない
        } else if (file.size === 0) {
          error = 'ファイルが破損している恐れがあります。'
        } else if (file.size > 300 * 1024 * 1024) {
          error = '300MB以上のファイルはアップロードできません。'
        } else if (!file.type || (file.type !== 'audio/wav' && file.type !== 'audio/wave' && file.type !== 'audio/x-wav' && file.type !== 'audio/x-pn-wav')) {
          error = 'wav形式のファイルをアップロードしてください。'
        }
      }
      if (error.length > 0) {
        alert(error)
        $error.html(`<div class="alert alert-danger">${error}</div>`) // error には指定の文字列しか入らないので、エスケープ不要
        $fileField.val(null)
      } else {
        Rails.fire($audioUploadForm.get(0), 'submit')
        $fileField.prop('disabled', true)
        $submitButton.prop('disabled', true)
        if (!$nameField.val()) {
          $nameField.val(file.name.replace(/.wav/i, '')).change()
        }
      }
    })
  }

  if ($audioEntryForm.is('*')) {
    // バリエーション登録の作品選択フォーム制御
    const $selectVariationChildren = $('.js-select-variation-children')
    if ($selectVariationChildren.is('*')) {
      const selectVariation = () => {
        const $variationTargetAudiosSelect = $selectVariationChildren.nextAll('div').children('.variation-target-audios')
        const $parentIdField = $variationTargetAudiosSelect.find('input[name*=\\[parent_id\\]]')
        const $copyrightInformationFieldSet = $('fieldset.js-copyright-information')
        const $copyrightInformationHiddenNote = $('.js-copyright-information-hidden-note')
        const $coverNoteWrapper = $('.js-cover-note-wrapper')
        const $variationTitleNote = $('.js-variation-title-note')

        if ($selectVariationChildren.prop('checked')) {
          $variationTargetAudiosSelect.removeClass('disabled')
          $parentIdField.prop('disabled', false)
          $copyrightInformationFieldSet.hide()
          $copyrightInformationFieldSet.find('input, button').prop('disabled', true)
          $copyrightInformationHiddenNote.show()
          $coverNoteWrapper.hide()
          $variationTitleNote.show()
        } else {
          $variationTargetAudiosSelect.addClass('disabled')
          $parentIdField.prop('disabled', true)
          $copyrightInformationFieldSet.show()
          $copyrightInformationFieldSet.find('input, button').prop('disabled', false)
          $copyrightInformationHiddenNote.hide()
          $coverNoteWrapper.show()
          $variationTitleNote.hide()
        }
      }
      $('[class*="js-select-variation"]').on('change', selectVariation)
      selectVariation()
    }

    // カバー・アレンジ作品の作曲者/作詞者フォーム注意書き制御
    const $selectOriginalSoundSourceCover = $('.js-select-original-sound-source-cover')
    if ($selectOriginalSoundSourceCover.is('*')) {
      const $composer = $('.js-composer')
      const $composerKana = $('.js-composer-kana')
      const $composerEng = $('.js-composer-eng')
      const $lyricist = $('.js-lyricist')
      const $lyricistKana = $('.js-lyricist-kana')
      const $lyricistEng = $('.js-lyricist-eng')
      const originalComposer = $composer.val()
      const originalComposerKana = $composerKana.val()
      const originalComposerEng = $composerEng.val()
      const originalLyricist = $lyricist.val()
      const originalLyricistKana = $lyricistKana.val()
      const originalLyricistEng = $lyricistEng.val()

      let currentOriginalSoundSource = $selectOriginalSoundSourceCover.prop('checked') ? 'cover' : 'original'

      const selectOriginalSoundSource = () => {
        const $coverNote = $('.js-cover-note')
        const $agreementPd = $('.js-agreement-pd')
        if ($selectOriginalSoundSourceCover.prop('checked')) {
          $coverNote.show()
          $agreementPd.show()
          $agreementPd.children('input[type=checkbox]').prop('disabled', false)
          // オリジナル→カバーの切替時のみ発火
          if (currentOriginalSoundSource === 'original') {
            $composer.val('')
            $composerKana.val('')
            $composerEng.val('')
            $lyricist.val('')
            $lyricistKana.val('')
            $lyricistEng.val('')
          }
          currentOriginalSoundSource = 'cover'
        } else {
          $coverNote.hide()
          $agreementPd.hide()
          $agreementPd.children('input[type=checkbox]').prop('disabled', true)
          // カバー→オリジナルの切替時のみ発火
          if (currentOriginalSoundSource === 'cover') {
            $composer.val(originalComposer)
            $composerKana.val(originalComposerKana)
            $composerEng.val(originalComposerEng)
            $lyricist.val(originalLyricist)
            $lyricistKana.val(originalLyricistKana)
            $lyricistEng.val(originalLyricistEng)
          }
          currentOriginalSoundSource = 'original'
        }
      }
      $('[class*="js-select-original-sound-source"]').on('change', selectOriginalSoundSource)
      selectOriginalSoundSource()
    }

    // BPM設定のフォーム制御
    const $bpmUnset = $('.js-bpm-unset')
    const $bpmValue = $('.js-bpm-value')
    const $bpmFree = $('.js-bpm-free')
    const $bpmValueField = $('.js-bpm-value-field')
    const $bpmHiddenField = $('.js-bpm-hidden-field')
    if ($bpmValueField.is('*')) {
      const setBpm = () => $bpmHiddenField.val($bpmValueField.val())
      const selectBpm = () => {
        if ($bpmUnset.prop('checked')) {
          $bpmValueField.prop('disabled', true)
          $bpmHiddenField.val('')
        } else if ($bpmValue.prop('checked')) {
          $bpmValueField.prop('disabled', false)
          $bpmHiddenField.val($bpmValueField.val())
        } else if ($bpmFree.prop('checked')) {
          $bpmValueField.prop('disabled', true)
          $bpmHiddenField.val(0)
        }
      }
      $bpmValueField.on('change', setBpm)
      $('[class*="js-bpm"]').on('keyup change', selectBpm)
      setBpm()
      selectBpm()
    }

    // ファイルをアップロードしないと登録ボタンを押せなくする
    if ($audioUploadForm.is('*')) {
      $('.js-audio-submit-btn').on('click', (e) => {
        if (!$audioUploadForm.get(0).checkValidity()) {
          $audioUploadForm.get(0).reportValidity()
          e.preventDefault()
          return false
        }
      })
    }
  }
})
