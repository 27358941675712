import { Popover } from 'bootstrap'

document.addEventListener('DOMContentLoaded', () => {
  // ポップオーバーの初期化
  document.querySelectorAll('[data-bs-toggle="popover"]').forEach((popoverTriggerElement) => new Popover(popoverTriggerElement))

  // 別のポップオーバーを表示、もしくは領域外クリックでポップオーバーを閉じる
  const popoverButtonSelector = '[data-bs-toggle=popover]'
  const handlePopover = (e) => {
    if (e.target.closest('.popover')) return

    const popoverButtons = document.querySelectorAll(`${popoverButtonSelector}`)
    const targetPopoverButton = e.target.closest(`${popoverButtonSelector}`)
    if (targetPopoverButton && targetPopoverButton.id) {
      popoverButtons.forEach((popoverButton) => {
        if (popoverButton.id !== targetPopoverButton.id) {
          Popover.getOrCreateInstance(popoverButton).hide()
        }
      })
    } else {
      popoverButtons.forEach((popoverButton) => {
        Popover.getOrCreateInstance(popoverButton).hide()
      })
    }
  }
  document.addEventListener('click', handlePopover)
  document.addEventListener('touchend', handlePopover)
})
